@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'kulimpark';
    src: url('../public/fonts/KulimPark-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'kulimpark-semibold';
    src: url('../public/fonts/KulimPark-SemiBold.ttf') format('truetype');
}


body{
    font-family: 'kulimpark';
    box-sizing: border-box;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
    max-height: 30rem !important;
}
/* COLOR DE LOS INPUT , NO SE PUEDEN CAMBIAR SI NO ES ATRAVÉS DEL CSS */
.css-anu049-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
.css-1y7k1sw,
.css-1k3x8v3,
.css-1jf37tt,
.css-putrnr{
    color:black !important;
}

.css-14jik6a-MuiInputBase-input-MuiOutlinedInput-input,
.css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input,
.css-vubbuv,
.css-1drgtl0-MuiButtonBase-root-MuiIconButton-root,
.css-1k3ibsw-MuiButtonBase-root-MuiIconButton-root{
    color: rgb(71, 71, 71) !important;
}

.css-1drgtl0-MuiButtonBase-root-MuiIconButton-root:hover,
.css-1k3ibsw-MuiButtonBase-root-MuiIconButton-root:hover{
    background-color: rgba(0, 0, 0, 0.07) !important;
}

.tableContainer {
    max-height: 50vh;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #7a7b7c #d1d5db;
}

.tableContainer::-webkit-scrollbar {
    width: 12px;
}

.tableContainer::-webkit-scrollbar-thumb {
    background-color: #6b7280;
    border-radius: 12px;
}

.tableContainer::-webkit-scrollbar-track {
    background-color: #d1d5db;
}

/* DATEPICKER, SELECT Y AUTOCOMPLETE PADDING */
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
	padding: 9px 14px !important;
}

.css-1gbmlfq-MuiDataGrid-root .MuiDataGrid-iconSeparator,
.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-iconSeparator{
    color: #359597 !important;
}

.row-presente {
    background-color: #beac35;
    color: white;
}

.row-presente:hover {
    background-color: #beac35 !important;
    color: white;
}

.row-presente.MuiDataGrid-row.Mui-selected.MuiDataGrid-row--editable {
    background-color: #beac35 !important;
}

.row-presente .MuiCheckbox-root, .row-atendido .MuiCheckbox-root {
    color: white !important;
}

.row-atendido {
    background-color: #00aab0;
    color: white;
}

.row-atendido:hover {
    background-color: #00aab0 !important;
    color: white;
}

.row-atendido.MuiDataGrid-row.Mui-selected.MuiDataGrid-row--editable {
    background-color: #00aab0 !important;
}

.just-line-break { white-space: pre-line; }
